import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/todo",
      name: "todo",
      component: () => import("@/views/project/todo/Todo.vue"),
      meta: {
        requiredAuth: true,
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
      },
    },
    {
      path: "/check/lists",
      name: "check-list",
      component: () => import("@/views/project/check_list/CheckList.vue"),
      meta: {
        requiredAuth: true,
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
      },
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import("@/views/project/project/Index.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Projects",
        breadcrumb: [
          {
            text: "Dashboard",
            to: '/'
          },
          {
            text: "Projects",
            active: true,
          },
        ],
      },
    },
    {
      path: "/projects/:id",
      name: "projects",
      component: () => import("@/views/project/project/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Project Manage",
        breadcrumb: [
          {
            text: "Dashboard",
            to: '/',
          },
          {
            text: "Projects",
            to: '/projects',
          },
          {
            text: "Projects Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/default/tasks",
      name: "default-tasks",
      component: () => import("@/views/project/default_task/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Default Task Manage",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/default/main/tasks",
      name: "default-main-tasks",
      component: () => import("@/views/project/default_main_task/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Default Main Task Manage",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  if (localStorage.getItem("token")) {
    store.dispatch("CheckAuth");
  }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.visitor)) {
    if (store.getters.loggedIn) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
