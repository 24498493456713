export default {
  state: {},
  getters: {},
  actions: {
    getPermissions(content) {
      axios
        .post("/permission")
        .then((res) => {
          let permissions = {
            default_task: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },

            project: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },

            main_task: {
              access: false,
              all_access: false,
              create: false,
              edit: false,
              delete: false,
            },

            task: {
              access: false,
              all_access: false,
              create: false,
              edit: false,
              delete: false,
              manage: false,
            },
          };
          res.data.permissions.forEach((permission) => {
             // Default Task Permission
            if (permission.slug == "employee.default.task.access") {
              permissions.default_task.access = true;
            }
            if (permission.slug == "employee.default.task.create") {
              permissions.default_task.create = true;
            }
            if (permission.slug == "employee.default.task.edit") {
              permissions.default_task.edit = true;
            }
            if (permission.slug == "employee.default.task.destroy") {
              permissions.default_task.delete = true;
            }

            // Project Permission
            if (permission.slug == "employee.project.access") {
              permissions.project.access = true;
            }
            if (permission.slug == "employee.project.create") {
              permissions.project.create = true;
            }
            if (permission.slug == "employee.project.edit") {
              permissions.project.edit = true;
            }
            if (permission.slug == "employee.project.destroy") {
              permissions.project.delete = true;
            }

            // Main Task Permission
            if (permission.slug == "employee.main.task.access") {
              permissions.main_task.access = true;
            }
            if (permission.slug == "employee.main.task.create") {
              permissions.main_task.create = true;
            }
            if (permission.slug == "employee.main.task.edit") {
              permissions.main_task.edit = true;
            }
            if (permission.slug == "employee.main.task.destroy") {
              permissions.main_task.delete = true;
            }
            if (permission.slug == "employee.main.task.all.access") {
              permissions.main_task.all_access = true;
            }

            // Task Permission
            if (permission.slug == "employee.task.access") {
              permissions.task.access = true;
            }
            if (permission.slug == "employee.task.create") {
              permissions.task.create = true;
            }
            if (permission.slug == "employee.task.edit") {
              permissions.task.edit = true;
            }
            if (permission.slug == "employee.task.destroy") {
              permissions.task.delete = true;
            }
            if (permission.slug == "employee.task.manage") {
              permissions.task.manage = true;
            }
            if (permission.slug == "employee.task.all.access") {
              permissions.task.all_access = true;
            }
          });
          content.commit("getPermissions", permissions);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
