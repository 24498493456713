import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import Swal from "sweetalert2";
window.Swal = Swal;

// import axios from "axios";
window.axios = require("axios");
axios.defaults.baseURL = "https://hr.citsmp.com/api/employee";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import moment from "moment";

Vue.filter("date_format", function (a) {
  return moment(a).format("Do MMMM YYYY");
});

Vue.filter("date_time_format", function (a) {
  return moment(a).format("Do MMMM YYYY, h:mm A");
});
Vue.filter("date_time_format_short", function (a) {
  return moment(a).format("Do MMM YY, h:mm A");
});
Vue.filter("time_format_short", function (a) {
  return moment(a).format("h:mm A");
});
Vue.filter("date_short", function (a) {
  return moment(a).format("Do MMM, YY");
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);
// Pagination Component
Vue.component("pagination", require("laravel-vue-pagination"));

// import core styles
require("@core/scss/core.scss");

import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import locale from "view-design/dist/locale/en-US";

Vue.use(ViewUI, { locale: locale });

import common from "./common";
import axios from "axios";
Vue.mixin(common);

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
